import React, { useEffect, useState } from 'react'
import Trix from "trix"
import './EditorTextoEmail.css'

import { IconButton } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';

export const EditorTextoEmail = ({setTextoEmailEditor, correoGuardado}) => {
  const[cargado, setCargado] = useState(false)
  useEffect(() => {
    if (correoGuardado !== undefined && correoGuardado !== '' && !cargado) {
      setTextoEmailEditor(correoGuardado);
      setCargado(true);
    }
  }, [correoGuardado, cargado, setTextoEmailEditor]);

  useEffect(() => {
    const handleTrixChange = () => {
      var valor = document.getElementById("inputEmail").value;
      setTextoEmailEditor(valor);
    };

    document.addEventListener("trix-change", handleTrixChange);

    // Cleanup listener on component unmount
    return () => {
      document.removeEventListener("trix-change", handleTrixChange);
    };
  }, [setTextoEmailEditor]);

  return (
    <div>
      <input id="inputEmail" value={correoGuardado} type='hidden' name="content"></input>
      <trix-editor className="trix-content" input="inputEmail"></trix-editor>
    </div>
  );

}
